<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <div class="flex">
        <div class="">
          <button
            class="p-2 rounded-md text-xs text-black"
            @click="
              $router.push({
                name: 'Verifications',
              })
            "
          >
            &laquo; Back
          </button>
        </div>
      </div>
      <h1 class="text-2xl font-bold">Verification Details</h1>
      <div
        class="bg-gray-100 mt-10 py-2 w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4"
      >
        <div
          class="flex justify-between w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4"
        >
          <div
            :class="
              currentTab === 'business' ? 'border-b-2 border-blue-800 pb-2' : ''
            "
            @click="moveTab('business')"
            class="cursor-pointer"
          >
            Business Info
          </div>
          <div
            class="mx-5 cursor-pointer"
            :class="
              currentTab === 'bvn' ? 'border-b-2 border-blue-800 pb-2' : ''
            "
            @click="moveTab('bvn')"
          >
            BVN Details
          </div>
          <div
            class="cursor-pointer"
            :class="
              currentTab === 'id' ? 'border-b-2 border-blue-800 pb-2' : ''
            "
            @click="moveTab('id')"
          >
            ID Info
          </div>
          <div
            class="cursor-pointer"
            :class="
              currentTab === 'address' ? 'border-b-2 border-blue-800 pb-2' : ''
            "
            @click="moveTab('address')"
          >
            Address Info
          </div>
          <div
            class="cursor-pointer"
            :class="
              currentTab === 'corporate'
                ? 'border-b-2 border-blue-800 pb-2'
                : ''
            "
            @click="moveTab('corporate')"
          >
            Corporate Info
          </div>
        </div>
      </div>
      <div class="flex my-10" v-if="currentTab === 'business'">
        <div class="w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4">
          <div
            class="flex justify-center flex-col p-5 border-2 border-gray-50 shadow-lg rounded-md"
          >
            <h4 class="text-xl font-bold">Business Information</h4>
            <div class="grid grid-cols-2 gap-4 mt-5">
              <div class="py-1">
                <h5 class="text-xs font-bold">Business ID</h5>
                <p class="flex text-sm mb-3">{{ documents.business_id }}</p>
                <p class="text-xs font-bold">Registered Name</p>
                <p class="flex mb-3">{{ documents.registered_name }}</p>
                <h5 class="text-xs font-bold">Merchant Phone number</h5>
                <p class="flex text-sm mb-3">
                  {{ documents.merchant_phone_number }}
                </p>
                <p class="text-xs font-bold">Date of Signup</p>
                <p class="flex mb-3">
                  {{
                    moment(documents.date_of_signup).format(
                      "DD MMM YYYY - h:mm:ss a"
                    )
                  }}
                </p>
                <h5 class="text-xs font-bold">KYC Status</h5>
                <p
                  class="text-sm px-2 mb-3 w-auto inline-block"
                  :class="
                    documents.kyc === 'VERIFIED'
                      ? 'bg-green-600 text-white'
                      : 'bg-yellow-100 text-white'
                  "
                >
                  {{ documents.kyc }}
                </p>
              </div>
              <div class="py-1">
                <h5 class="text-xs font-bold">Business/Trading Name</h5>
                <p class="flex text-sm mb-3">{{ documents.business_name }}</p>
                <h5 class="text-xs font-bold">Merchant Name</h5>
                <p class="text-left mb-3">
                  {{ documents.merchant_name }}
                </p>
                <p class="text-xs font-bold">Merchant Email:</p>
                <p class="flex mb-3">{{ documents.email }}</p>
                <p class="text-xs font-bold">Last KYC update</p>
                <p class="flex mb-3">
                  {{
                    moment(bvn.lastKYCStatus).format("DD MMM YYYY - h:mm:ss a")
                  }}
                </p>

                <p class="text-xs font-bold">Contact Verification Status</p>
                <p
                  class="text-sm px-2 mb-3 w-auto inline-block"
                  :class="
                    documents.contact_verification_status === 'VERIFIED'
                      ? 'bg-green-600 text-white'
                      : 'bg-yellow-100 text-white'
                  "
                >
                  {{ documents.contact_verification_status }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col my-5" v-if="currentTab === 'bvn'">
        <div class="w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4 mb-3">
          <span
            class="text-right px-3 py-2 text-xs uppercase text-white"
            style="float: right"
            :class="
              bvn.status == 'VERIFIED'
                ? 'bg-green-500'
                : bvn.status == 'FAILED'
                ? 'bg-red-600'
                : 'bg-gray-600'
            "
          >
            {{
              bvn.status == "VERIFIED"
                ? "VERIFIED"
                : bvn.status == "FAILED"
                ? "FAILED"
                : "Awaiting approval"
            }}
          </span>
        </div>
        <div class="w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4 mt-3">
          <div
            class="flex justify-center flex-col p-5 border-2 border-gray-200 shadow-lg rounded-md"
          >
            <h4 class="text-xl font-bold">BVN Details</h4>
            <div class="grid grid-cols-3 gap-3 w-full">
              <div class="justify-center flex-col items-center py-2">
                <div>
                  <h4 class="font-bold text-xl">Image</h4>
                  <img
                    :src="bvn.document_image"
                    alt="No Selfie photo to show"
                    style="width: 200px; height: 200px"
                  />
                  <button
                    @click="handleViewImage(bvn.document_image)"
                    class="uppercase shadow mt-2 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
                  >
                    View Image
                  </button>
                </div>
              </div>
              <div class="justify-center flex-col items-center py-2">
                <h4 class="font-bold text-xs">First Name</h4>
                <p>
                  {{ bvn.first_name }}
                </p>
                <h4 class="font-bold text-xs mt-5">Last Name</h4>
                <p>
                  {{ bvn.last_name }}
                </p>
                <h4 class="font-bold text-xs mt-5">Phone number</h4>
                <p>
                  {{ bvn.phone_number || "N/A" }}
                </p>
                <h4 class="font-bold text-xs mt-5">Address</h4>
                <p>
                  {{ bvn.address }}
                </p>
                <h4 class="font-bold text-xs mt-5">BVN Number</h4>
                <p>
                  {{ bvn.bvn_number }}
                </p>
              </div>
              <div class="justify-center flex-col items-center py-2">
                <h4 class="font-bold text-xs">Middle Name</h4>
                <p>
                  {{ bvn.middle_name }}
                </p>
                <h4 class="font-bold text-xs mt-5">Email</h4>
                <p>
                  {{ bvn.email || "N/A" }}
                </p>
                <h4 class="font-bold text-xs mt-5">DOB</h4>
                <p>
                  {{ bvn.dob }}
                </p>
                <h4 class="font-bold text-xs mt-5">Next of Kin</h4>
                <p>
                  {{ bvn.next_of_kin }}
                </p>
                <h4 class="font-bold text-xs mt-5">BVN Verification Status</h4>
                <p
                  class="text-white inline px-2"
                  :class="
                    bvn.status == 'VERIFIED'
                      ? 'bg-green-500'
                      : bvn.status == 'FAILED'
                      ? 'bg-red-600'
                      : 'bg-gray-600'
                  "
                >
                  {{ bvn.status || "N/A" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col my-10" v-if="currentTab === 'id'">
        <div class="w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4mb-3">
          <span
            class="text-right px-3 py-2 text-xs uppercase text-white"
            style="float: right"
            :class="
              id.status == 'VERIFIED'
                ? 'bg-green-500'
                : id.status == 'FAILED'
                ? 'bg-red-600'
                : 'bg-gray-600'
            "
          >
            {{
              id.status == "VERIFIED"
                ? "VERIFIED"
                : id.status == "FAILED"
                ? "FAILED"
                : "Awaiting approval"
            }}
          </span>
        </div>
        <div class="w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4">
          <div
            class="flex justify-center flex-col p-5 border-2 border-gray-200 shadow-lg rounded-md"
          >
            <h4 class="text-xl font-bold">ID Info</h4>
            <div class="grid grid-cols-3 gap-3 w-full">
              <div class="justify-center flex-col items-center py-2">
                <div>
                  <h4 class="font-bold text-xs">Image</h4>
                  <img
                    :src="id.document_image"
                    alt="No Selfie photo to show"
                    style="width: 200px; height: 200px"
                  />
                  <button
                    @click="handleViewImage(id.document_image)"
                    class="uppercase shadow mt-2 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
                  >
                    View Image
                  </button>
                </div>
              </div>
              <div class="justify-center flex-col items-center py-2">
                <h4 class="font-bold text-xs">First Name</h4>
                <p>
                  {{ id.first_name }}
                </p>
                <h4 class="font-bold text-xs mt-5">Last Name</h4>
                <p>
                  {{ id.last_name }}
                </p>
                <h4 class="font-bold text-xs mt-5">Phone number</h4>
                <p>
                  {{ id.phone_number || "N/A" }}
                </p>
                <h4 class="font-bold text-xs mt-5">ID Number</h4>
                <p>
                  {{ id.id_number }}
                </p>
                <h4 class="font-bold text-xs mt-5">Tracking Number</h4>
                <p>
                  {{ id.tracking_reference }}
                </p>
              </div>
              <div class="justify-center flex-col items-center py-2">
                <h4 class="font-bold text-xs">Middle Name</h4>
                <p>
                  {{ id.middle_name }}
                </p>
                <h4 class="font-bold text-xs mt-5">DOB</h4>
                <p>
                  {{ id.dob || "N/A" }}
                </p>
                <h4 class="font-bold text-xs mt-5">ID Verification Status</h4>
                <p
                  class="text-white inline px-2"
                  :class="
                    id.status == 'VERIFIED'
                      ? 'bg-green-500'
                      : id.status == 'FAILED'
                      ? 'bg-red-600'
                      : 'bg-gray-600'
                  "
                >
                  {{ id.status || "N/A" }}
                </p>
                <h4 class="font-bold text-xs mt-5">Verified By</h4>
                <p>
                  {{ id.verifiedBy || "" }}
                </p>
              </div>
            </div>
            <div class="text-right mt-5" v-if="id.status === 'PENDING'">
              <button
                @click="handleIDModal(id, 'DECLINED')"
                class="inline-block mr-2 uppercase border-2 border-red-600 hover:bg-red-700 focus:shadow-outline focus:outline-none hover:text-white text-red-800 h-10 text-xs px-5 rounded"
              >
                Reject
              </button>
              <button
                @click="handleIDModal(id, 'APPROVED')"
                class="inline-block uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-5 rounded"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex my-10 flex-col" v-if="currentTab === 'address'">
        <div class="w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4 mb-3">
          <span
            class="text-right px-3 py-2 text-xs uppercase text-white"
            style="float: right"
            :class="
              address.status == 'VERIFIED'
                ? 'bg-green-500'
                : address.status == 'FAILED'
                ? 'bg-red-600'
                : 'bg-gray-600'
            "
          >
            {{
              address.status == "VERIFIED"
                ? "VERIFIED"
                : address.status == "FAILED"
                ? "FAILED"
                : "Awaiting approval"
            }}
          </span>
        </div>
        <div class="w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4">
          <div
            class="flex justify-center flex-col p-5 border-2 border-gray-200 shadow-lg rounded-md"
          >
            <h4 class="text-xl font-bold">Address Info</h4>
            <div class="grid grid-cols-3 gap-3 w-full">
              <div class="justify-center flex-col items-center py-2">
                <div>
                  <h4 class="font-bold text-xs">Utility Bill Image</h4>
                  <img
                    :src="address.utility_bill_image"
                    alt="No Utility bill photo to show"
                    style="width: 200px; height: 200px"
                  />
                  <button
                    @click="handleViewImage(address.utility_bill_image)"
                    class="uppercase shadow mt-2 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
                  >
                    View Image
                  </button>
                  <h4 class="font-bold text-xs mt-5">Location Image</h4>
                  <img
                    :src="address.location_image"
                    alt="No location photo to show"
                    style="width: 200px; height: 200px"
                  />
                  <button
                    @click="handleViewImage(address.location_image)"
                    class="uppercase shadow mt-2 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
                  >
                    View Image
                  </button>
                </div>
              </div>
              <div class="justify-center flex-col items-center py-2">
                <h4 class="font-bold text-xs">Business Address</h4>
                <p>
                  {{ address.business_address }}
                </p>
                <h4 class="font-bold text-xs mt-5">ID Number</h4>
                <p>
                  {{ address.id_number }}
                </p>
                <h4 class="font-bold text-xs mt-5">Merchant ID</h4>
                <p>
                  {{ address.merchant_id || "N/A" }}
                </p>
              </div>
              <div class="justify-center flex-col items-center py-2">
                <h4 class="font-bold text-xs">ID Type</h4>
                <p>
                  {{ address.id_type }}
                </p>
                <h4 class="font-bold text-xs mt-5">
                  Address Verification status
                </h4>
                <p
                  class="text-white inline px-2"
                  :class="
                    address.status == 'VERIFIED'
                      ? 'bg-green-500'
                      : address.status == 'FAILED'
                      ? 'bg-red-600'
                      : 'bg-gray-600'
                  "
                >
                  {{ address.add_verification_status || "N/A" }}
                </p>
              </div>
            </div>
            <div class="text-right mt-5" v-if="address.status === 'PENDING'">
              <button
                @click="handleAddressModal(address, 'DECLINED')"
                class="inline-block mr-2 uppercase border-2 border-red-600 hover:bg-red-700 focus:shadow-outline focus:outline-none hover:text-white text-red-800 h-10 text-xs px-5 rounded"
              >
                Reject
              </button>
              <button
                @click="handleAddressModal(address, 'APPROVED')"
                class="inline-block uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-5 rounded"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex my-10 flex-col" v-if="currentTab === 'corporate'">
        <div class="w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4 mb-3">
          <span
            class="text-right px-3 py-2 text-xs uppercase text-white"
            style="float: right"
            :class="
              corporate.status == 'VERIFIED'
                ? 'bg-green-500'
                : corporate.status == 'FAILED'
                ? 'bg-red-600'
                : 'bg-gray-600'
            "
          >
            {{
              corporate.status == "VERIFIED"
                ? "VERIFIED"
                : corporate.status == "FAILED"
                ? "FAILED"
                : "Awaiting approval"
            }}
          </span>
        </div>
        <div class="w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4">
          <div
            class="flex justify-center flex-col p-5 border-2 border-gray-200 shadow-lg rounded-md"
          >
            <h4 class="text-xl font-bold">Corporate Info</h4>
            <div class="grid grid-cols-3 gap-3 w-full">
              <div class="justify-center flex-col items-center py-2">
                <div>
                  <h4 class="font-bold text-xs">Registered Name</h4>
                  <p>
                    {{ documents.business_name || "N/A" }}
                  </p>
                  <h4 class="font-bold text-xs mt-5">Legal Name</h4>
                  <p>
                    {{ corporate.legal_name || "N/A" }}
                  </p>
                  <h4 class="font-bold text-xs mt-5">RC/BN number</h4>
                  <p>
                    {{ corporate.business_number || "N/A" }}
                  </p>
                  <h4 class="font-bold text-xs mt-5">CAC certificate upload</h4>
                  <img
                    :src="corporate.cac_cert"
                    alt="No cac certificate upload"
                    style="width: 200px; height: 200px"
                    class="mb-5"
                  />
                  <h4 class="font-bold text-xs">Certified Application Form</h4>
                  <img
                    :src="corporate.certified_application"
                    alt="No location photo to show"
                    style="width: 200px; height: 200px"
                  />
                  <h4 class="font-bold text-xs">
                    Corporate verification status:
                  </h4>
                  <p
                    class="inline px-1"
                    :class="
                      corporate.status == 'VERIFIED'
                        ? 'bg-green-500 text-black'
                        : corporate.status == 'FAILED'
                        ? 'bg-red-600 text-white'
                        : 'bg-gray-600 text-white'
                    "
                  >
                    {{ corporate.status || "N/A" }}
                  </p>
                </div>
              </div>
              <div class="justify-center flex-col items-center py-2">
                <h4 class="font-bold text-xs">Business Registration Type</h4>
                <p>
                  {{ corporate.business_registration_type || "N/A" }}
                </p>
                <h4 class="font-bold text-xs mt-5">
                  Tax Identification Number
                </h4>
                <p>
                  {{ corporate.tax_id || "N/A" }}
                </p>
                <h4 class="font-bold text-xs mt-5">Director Details</h4>
                <p>
                  {{ "N/A" }}
                </p>
                <h4 class="font-bold text-xs mt-5">Director ID</h4>
                <img
                  :src="corporate.director_id"
                  alt="No location photo to show"
                  style="width: 200px; height: 200px"
                />
                <h4 class="font-bold text-lg mt-5">Smile Identity Data</h4>
                <h5 class="font-bold text-xs mt-5">Business Name</h5>
                <p>
                  {{ corporate.provider_registered_name || "N/A" }}
                </p>
                <h5 class="font-bold text-xs mt-5">
                  Business Verification Status
                </h5>
                <p>
                  {{ corporate.corporate_verification_status || "N/A" }}
                </p>
                <h5 class="font-bold text-xs mt-5">Status</h5>
                <p>
                  {{ corporate.business_status || "N/A" }}
                </p>
              </div>
            </div>
            <div class="text-right mt-5" v-if="corporate.status === 'PENDING'">
              <button
                @click="handleCorporateModal(corporate, 'DECLINED')"
                class="inline-block mr-2 uppercase border-2 border-red-600 hover:bg-red-700 focus:shadow-outline focus:outline-none hover:text-white text-red-800 h-10 text-xs px-5 rounded"
              >
                Reject
              </button>
              <button
                @click="handleCorporateModal(corporate, 'APPROVED')"
                class="inline-block uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-5 rounded"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="approveBlacklistIDModal"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div class="w-2/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4">
          <span class="cursor-pointer" @click="approveBlacklistIDModal = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-lg float-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fill-rule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>
          </span>
          <p class="text-blue-900 font-semibold text-xl">
            Add reason for blacklisting
          </p>
          <div class="grid grid-cols-1 gap-5">
            <div class="flex flex-col pt-4">
              <textarea
                v-model="blacklistingID_reason"
                placeholder="Enter Reason for Rejection"
                class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                rows="4"
              ></textarea>
            </div>
          </div>
          <div class="justify-end flex">
            <button
              class="inline-block uppercase shadow mt-10 mx-3 leading-6 hover:bg-red-300 focus:shadow-outline focus:outline-none text-blue text-xs py-2 px-8 rounded"
              @click="approveBlacklistIDModal = false"
            >
              Cancel
            </button>
            <button
              @click="handleBlacklistingAction"
              class="inline-block uppercase shadow bg-blue-600 mt-10 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Done
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="rejectModal"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div class="w-2/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4">
          <span class="cursor-pointer" @click="rejectModal = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-lg float-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fill-rule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>
          </span>
          <p class="text-blue-900 font-semibold">Rejection List</p>
          <p class="text-xs">
            Choose rejection reason from list or enter rejection reason
          </p>
          <div class="flex flex-wrap xl:-mx-4">
            <div class="w-1/3 xl:my-4 xl:px-4">
              <label
                for="ID does not match Selfie"
                class="w-full p-5 border-2 d-inline-block border-gray-100 bg-white rounded-lg cursor-pointer hover:bg-blue-900 hover:text-white"
                style="display: inline-block"
              >
                <p class="text-xs">
                  <input
                    type="checkbox"
                    v-model="reject_reason_type"
                    id="ID does not match Selfie"
                  />
                  ID does not match Selfie
                </p>
              </label>
            </div>
          </div>
          <form @submit.prevent="disapproveKYC">
            <div class="grid grid-cols-1 gap-5">
              <div class="flex flex-col pt-4">
                <textarea
                  v-model="reject_reason"
                  placeholder="Enter Reason for Rejection"
                  class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                ></textarea>
              </div>
            </div>
            <button
              class="inline-block uppercase shadow mt-10 mx-3 leading-6 hover:bg-red-300 focus:shadow-outline focus:outline-none text-blue text-xs py-2 px-8 rounded"
              @click="rejectModal = false"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="inline-block uppercase shadow bg-blue-600 mt-10 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div
        v-if="imagePrompt"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/2 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <div class="block overflow-scroll">
            <img
              :src="previewImage"
              alt="No location photo to show"
              style="width: auto; height: auto"
              class="overflow-scroll"
            />

            <button
              @click="imagePrompt = false"
              class="block uppercase mx-auto hover:bg-red-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-red-600 text-xs font-bold py-2 rounded px-5 mt-3"
              style="width: inherit"
            >
              Close
            </button>
          </div>
          <span
            @click="imagePrompt = false"
            class="cursor-pointer absolute top-0 right-0 text-xl text-white bg-black p-3 rounded-full h-16 w-16 flex items-center justify-center m-5"
            >X</span
          >
        </div>
      </div>
      <div
        v-if="alertDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <i
            class="bi bi-check-circle-fill text-green-400 text-6xl"
            v-if="currKYC.status === 'APPROVED'"
          ></i>
          <i class="bi bi-x-circle-fill text-yellow-400 text-6xl" v-else></i>
          <p class="text-xl font-bold w-3/4 mx-auto text-center">
            {{ messg }}
          </p>
          <small class="text-xs"
            >Kindly confirm that you want to perform this action below.</small
          >
          <textarea
            v-if="currKYC.status === 'DECLINED'"
            v-model="comment"
            class="border-2 border-gray-200"
            rows="3"
          ></textarea>
          <div class="flex justify-between w-3/4 mt-5 mx-auto">
            <button
              @click="updateKYCStatus"
              class="uppercase shadow mx-auto hover:bg-blue-600 bg-blue-800 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 px-6 rounded"
            >
              Confirm
            </button>
            <button
              @click="alertDiv = false"
              class="uppercase shadow mx-auto hover:bg-red-600 bg-red-800 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 px-6 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      moment,
      index: null,
      isLoading: false,
      currentTab: "business",
      verified: false,
      rejectModal: false,
      alertDiv: false,
      messg: "",
      reject_reason: "",
      reject_reason_type: "ID does not match Selfie",
      approveBlacklistIDModal: false,
      blacklistingID_reason: "",
      comment: "",
      currKYC: {},
      payload: {
        businessId: "",
        businessName: "",
        Status: "",
        comment: "",
      },
      bvn: {
        _id: "",
        document_image: "",
        first_name: "",
        middle_name: "",
        address: "",
        bvn_number: "",
        dob: "",
        next_of_kin: "",
        status: "",
        last_name: "",
        lastKYCStatus: "",
      },
      id: {
        document_image: "",
        first_name: "",
        middle_name: "",
        address: "",
        bvn_number: "",
        dob: "",
        next_of_kin: "",
        status: "",
        last_name: "",
        tracking_reference: "",
        id_number: "",
        id: "",
        verifiedBy: "",
      },
      address: {
        utility_bill_image: "",
        location_image: [],
        business_address: "",
        id_number: "",
        merchant_id: "",
        id_type: "",
        add_verification_status: "",
        id: "",
        status: "",
      },
      documents: {
        business_name: "",
        merchant_phone_number: "",
        kyc: "",
        registered_name: "",
        email: "",
        contact_verification_status: "",
        business_id: "",

        merchant_id: "",
        date_of_signup: "",
        last_kyc_update: "",
        email_verification: "",
        phone_verification: "",
        selfie_response: [],
        selfiePhoto: "",
        match_score: "",
        match_threshold: "",
        max_score: "",
        idType: "",
        selfieVerificationStatus: "",
        documentVerificationStatus: "",
        firstname: "",
        middlename: "",
        lastname: "",
        gender: "",
        bvn: "",
        phone: "",
        birthdate: "",
        nationality: "",
        status: null,
        documentImageUrl: "",
        settlement_type: "",
        verificationStatus: "",
        document_type: "",
      },
      imagePrompt: false,
      previewImage: "",
      corporate: null,
    };
  },
  computed: {
    // formatDocuments() {
    //   return this.documents;
    // },
  },

  async created() {
    this.loadVerificationDocument();
  },

  methods: {
    handleViewImage(imgUrl) {
      this.previewImage = imgUrl;
      this.imagePrompt = true;
    },
    moveTab(tab) {
      this.currentTab = tab;
    },
    handleAddressModal(address, status) {
      this.messg =
        status === "DECLINED"
          ? "Are you sure you want to reject this verification"
          : "Are you sure you want to approve this verification";
      this.currKYC.id = address.id;
      this.currKYC.status = status;
      this.alertDiv = true;
    },
    handleCorporateModal(corporate, status) {
      this.messg =
        status === "DECLINED"
          ? "Are you sure you want to reject this verification"
          : "Are you sure you want to approve this verification";
      this.currKYC.id = corporate._id;
      this.currKYC.status = status;
      this.alertDiv = true;
    },
    handleBVNModal(bvn, status) {
      this.messg =
        status === "DECLINED"
          ? "Are you sure you want to reject this verification"
          : "Are you sure you want to approve this verification";
      this.currKYC.id = bvn._id;
      this.currKYC.status = status;
      this.alertDiv = true;
    },
    handleIDModal(id, status) {
      this.messg =
        status === "DECLINED"
          ? "Are you sure you want to reject this verification"
          : "Are you sure you want to approve this verification";
      this.currKYC.id = id.id;
      this.currKYC.status = status;
      this.alertDiv = true;
    },
    async updateKYCStatus() {
      const status =
        this.currKYC.status == "APPROVED" ? "APPROVED" : "DECLINED";
      const comment = "APPROVED" ? "" : this.comment;
      this.isLoading = true;
      let payload = {
        comment: this.comment,
        status,
        id: this.currKYC.id,
      };

      let res;

      if (status == "APPROVED") {
        res = await this.$store.dispatch("APPROVE_KYC", payload);
      } else {
        res = await this.$store.dispatch("DISAPPROVE_KYC", payload);
      }

      if (res.status) {
        alert(res.message);
        this.isLoading = false;
        this.alertDiv = false;
        this.loadVerificationDocument();
      } else {
        alert(res.message);
        this.isLoading = false;
      }
    },
    async fetchTransactionRef(kycId) {
      this.isLoading = true;
      let res = await this.$store.dispatch(
        "FETCH_SINGLE_VERIFICATION_DOCUMENT",
        {
          id: kycId,
        }
      );
      if (res.status) {
        this.isLoading = false;
        this.id.tracking_reference = res.data.verificationData.meta.smileJobId;
        this.id.verifiedBy = res.data.verificationData.provider;
        this.id.phone_number = res.data.verificationData.phone;
      }
    },
    async loadVerificationDocument() {
      this.isLoading = true;
      let res = await this.$store.dispatch(
        "FETCH_SINGLE_VERIFICATION_DOCUMENT_NEW",
        {
          id: this.$route.params.id,
        }
      );
      this.isLoading = false;
      // console.log(res.data);
      if (res.status) {
        this.isLoading = false;
        this.corporate = res.data.corporate_info;
        this.documents.business_name =
          res.data.business_name === "" ? "N/A" : res.data.business_name;

        this.documents.business_id =
          res.data.business_id === "" ? "N/A" : res.data.business_id;
        this.documents.merchant_name =
          res.data.merchant_name === "" ? "N/A" : res.data.merchant_name;

        this.documents.merchant_phone_number =
          res.data.phone_number === "" ? "N/A" : res.data.phone_number;

        this.documents.kyc =
          res.data.kyc_status === "" ? "N/A" : res.data.kyc_status;

        this.documents.registered_name =
          res.data.registered_name === "" ? "N/A" : res.data.registered_name;

        this.documents.email = res.data.email === "" ? "N/A" : res.data.email;

        this.documents.contact_verification_status =
          res.data.contact_verification_status === ""
            ? "N/A"
            : res.data.contact_verification_status;

        this.documents.date_of_signup = res.data.date_of_signUp;

        this.bvn.lastKYCStatus = res.data.bvn.updated_at;
        this.bvn._id = res.data.bvn._id;
        this.bvn.document_image =
          res.data.bvn.document_image === ""
            ? "N/A"
            : res.data.bvn.document_image;
        this.bvn.first_name =
          res.data.bvn.first_name === "" ? "N/A" : res.data.bvn.first_name;
        this.bvn.last_name =
          res.data.bvn.first_name === "" ? "N/A" : res.data.bvn.last_name;
        this.bvn.middle_name =
          res.data.bvn.middle_name === "" ? "N/A" : res.data.bvn.middle_name;
        this.bvn.address =
          res.data.bvn.address === "" ? "N/A" : res.data.bvn.address;
        this.bvn.bvn_number =
          res.data.bvn.bvn_number === "" ? "N/A" : res.data.bvn.bvn_number;
        this.bvn.dob = res.data.bvn.dob === "" ? "N/A" : res.data.bvn.dob;
        this.bvn.next_of_kin =
          res.data.bvn.next_of_kin === "" ? "N/A" : res.data.bvn.next_of_kin;
        this.bvn.status =
          res.data.bvn.status === "" ? "N/A" : res.data.bvn.status;

        this.id.document_image =
          res.data.id_info.document_image === ""
            ? "N/A"
            : res.data.id_info.document_image;
        this.id.first_name =
          res.data.id_info.first_name === ""
            ? "N/A"
            : res.data.id_info.first_name;
        this.id.last_name =
          res.data.id_info.first_name === ""
            ? "N/A"
            : res.data.id_info.last_name;
        this.id.middle_name =
          res.data.id_info.middle_name === ""
            ? "N/A"
            : res.data.id_info.middle_name;
        this.id.address =
          res.data.id_info.address === "" ? "N/A" : res.data.id_info.address;
        this.id.id_info_number =
          res.data.id_info.id_info_number === ""
            ? "N/A"
            : res.data.id_info.id_info_number;
        this.id.dob =
          res.data.id_info.dob === "" ? "N/A" : res.data.id_info.dob;
        this.id.next_of_kin =
          res.data.id_info.next_of_kin === ""
            ? "N/A"
            : res.data.id_info.next_of_kin;

        this.id.status =
          res.data.id_info.status === "" ? "N/A" : res.data.id_info.status;
        this.id.tracking_reference =
          res.data.id_info.tracking_reference === ""
            ? "N/A"
            : res.data.id_info.tracking_reference;
        this.id.id_number =
          res.data.id_info.id_number === ""
            ? "N/A"
            : res.data.id_info.id_number;

        this.id.id = res.data.id_info._id === "" ? "N/A" : res.data.id_info._id;

        await this.fetchTransactionRef(res.data.id_info._id);

        this.address.utility_bill_image =
          res.data.address_info.utility_bill_image === ""
            ? "N/A"
            : res.data.address_info.utility_bill_image;

        const add_location_image =
          res.data.address_info.location_image === ""
            ? "N/A"
            : res.data.address_info.location_image;
        this.address.location_image.push(add_location_image);

        console.log(this.address.location_image);

        this.address.business_address =
          res.data.address_info.business_address === ""
            ? "N/A"
            : res.data.address_info.business_address;

        this.address.status =
          res.data.address_info.status === ""
            ? "N/A"
            : res.data.address_info.status;
        this.address.id_number =
          res.data.address_info.id_number === ""
            ? "N/A"
            : res.data.address_info.id_number;
        this.address.merchant_id =
          res.data.address_info.merchant_id === ""
            ? "N/A"
            : res.data.address_info.merchant_id;
        this.address.id_type =
          res.data.address_info.utility_bill_type === ""
            ? "N/A"
            : res.data.address_info.utility_bill_type;

        this.address.add_verification_status =
          res.data.address_info.status === ""
            ? "N/A"
            : res.data.address_info.status;

        this.address.id =
          res.data.address_info._id === "" ? "N/A" : res.data.address_info._id;
      } else {
        this.isLoading = false;
        alert("Sorry, could not find any detail for this record");
      }
    },
    dismissModal(e) {
      const target = e.target;
      if (target.classList.contains("modal")) {
        this.rejectModal = false;
        console.log("Close modal");
      }
    },
    async approveKYC() {
      const response = confirm("Are you sure you want to do this?");
      if (response) {
        this.isLoading = true;
        let payload = {
          comment: "",
          status: "APPROVED",
          id: this.$route.params.id,
        };
        let res = await this.$store.dispatch("APPROVE_KYC", payload);

        if (res.status) {
          alert("Success!");
          this.$router.go();
          this.verified = true;
          this.isLoading = false;
        } else {
          this.isLoading = false;
          alert(res.error);
        }
      }
      this.isLoading = false;
    },
    async disapproveKYC() {
      const response = confirm("Are you sure you want to do this?");
      if (response) {
        this.isLoading = true;
        let reason =
          this.reject_reason !== ""
            ? this.reject_reason
            : this.reject_reason_type;
        let payload = {
          comment: reason,
          status: "DECLINED",
          id: this.$route.params.id,
        };
        let res = await this.$store.dispatch("DISAPPROVE_KYC", payload);

        if (res.status) {
          this.isLoading = false;
          alert("Success!");
          this.$router.go();
        } else {
          this.isLoading = false;
          alert(res.error);
        }
      }
    },
    async handleBlacklistingAction() {
      const response = confirm("Are you sure you want to do this?");
      if (response) {
        this.isLoading = true;
        let payload = {
          note: this.blacklistingID_reason,
          idNumber: this.documents.verificationReference,
          idType: "bvn",
          id: this.documents.merchant_id,
        };
        let res = await this.$store.dispatch("BLACKLIST_ID", payload);
        this.isLoading = false;

        if (res.status) {
          alert("Success!");
          this.loadVerificationDocument();
          this.approveBlacklistIDModal = false;
        } else {
          this.isLoading = false;
          alert(res.error);
        }
      }
    },
  },
};
</script>
<style>
.images-wrapper .image-wrapper .image {
  cursor: pointer;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100%;
}
</style>
